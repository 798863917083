import { globalState } from '../state/globalState';
import { padStart } from 'lodash';
import pkg from '../../package.json';
import { getFilter, getFilters, setFilter } from '../state/filters';

export function getBaseUrl() {
	try {
		return process.env.API_URL; // will come from committed .env file, or uncommitted .env.local file
	} catch (err) {
		return 'https://api.rossware.com';
	}
}

export function encodeQueryObject(object) {
	if (!object) return '';
	const keys = Object.keys(object);

	return keys.filter(key => object[key] !== undefined).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`).join('&');
}

export function decodeQueryObject(query) {
	if (!query) return {};
	const pairs = query.split('&');
	const results = {};
	for (let pair of pairs) {
		const [key, value] = pair.split('=');
		results[decodeURIComponent(key)] = decodeURIComponent(value);
	}
	return results;
}

export function joinWithSingleForwardSlash(...parts) {
	if (!parts || parts.length === 0) return '';

	let results = '';
	for (let dex = 0; dex < parts.length; dex += 1) {
		let part = parts[dex];
		if (dex > 0) part = part.replace(/^\/*/, '/'); // one leading /
		if (dex < parts.length - 1) part = part.replace(/\/+$/, ''); // remove trailing /
		results += part;
	}
	return results;
}

export function getGlobalAuthParams() {
	const { business_id, business_password, tech_code, tech_password } = globalState.get(['auth']) || {};

	return {
		UserID: padStart(business_id, 4, '0'),
		business_id: padStart(business_id, 4, '0'),
		Password: business_password,
		business_password: business_password,
		TechCode: tech_code?.toUpperCase(),
		tech_code: tech_code?.toUpperCase(),
		TechPass: tech_password,
		tech_pass: tech_password,
		VersionUsed: `s${pkg.version}`,
		Version: `s${pkg.version}`,
		ClientPlatform: 'futuresched',
		OsUsed: 'web',
		application: 'futuresched',
	};
}

let bodyMethods = new Set(['POST', 'PATCH']);
export async function authenticatedFetch(url, options = {}) {
	let { query, body, ...rest } = options;

	if (!options.method) options.method = 'GET';

	if (bodyMethods.has(options.method.toUpperCase())) {
		body = Object.assign({}, getGlobalAuthParams(), body || {});
	} else {
		if (!query) {
			if (url.indexOf('?') >= 0) {
				const parts = url.split('?');
				url = parts[0];
				query = decodeQueryObject(parts[1]);
			}
		}
		query = Object.assign({}, getGlobalAuthParams(), body, query);
		body = undefined;
	}

	if (!options.headers) options.headers = {};
	if (!options.headers['Content-Type']) options.headers['Content-Type'] = 'application/json';
	if (!options.headers.Accept) options.headers.Accept = 'application/json';

	const fullUrl = url + (query ? `?${encodeQueryObject(query)}` : '');

	const fetchParams = {
		...rest,
		method: options.method,
		body,
	};

	if (process.env.LOG_REQUESTS === 'yes') console.log('requesting', fullUrl, fetchParams);

	const response = await fetch(fullUrl, fetchParams);
	const raw = await response.text();
	let parsed;

	try {
		parsed = JSON.parse(raw);
	} catch (err) {
		console.warn('failed to parse json', err, raw);
	}

	if (process.env.LOG_REQUESTS === 'yes') console.log('response', response.status, parsed || (raw ? raw.slice(0, 100) : raw));

	return {
		success: !!response.ok,
		status: response.status,
		ok: response.ok,
		raw,
		parsed,
	};
}

export async function authenticatedFetchWithRetries(url, options, retries = 10, retryDelay = 500) {
	let response;
	for (let dex = 0; dex < retries; dex += 1) {
		response = await authenticatedFetch(url, options);
		if (response.success) return response;
		if ((response.status >= 400 && response.status < 500) || !response.status) return response; // 400s are intentional and shouldn't be retried
		await new Promise(resolve => setTimeout(resolve, retryDelay));
	}
	return response;
}

export function authenticatedApiFetch(suburl, options) {
	const fullUrl = joinWithSingleForwardSlash(getBaseUrl(), suburl);

	return authenticatedFetchWithRetries(fullUrl, options);
}

export async function authenticateAndSetState() {
	const response = await authenticatedApiFetch('/v2/authenticateweb');

	const { success, parsed } = response;

	if (success) {
		if (parsed.SpclDutiesAndRights.charAt(11) === '0' || (!parsed.SpclDutiesAndRights.charAt(11) && parsed.DisableFS === 'N')) {
			if (parsed.SpclDutiesAndRights.charAt(13) === '1') {
				globalState.set(['ephemeral', 'can_triage_all'], true);
				if (!getFilter('techs')) {
					console.log('resetting techs filter', JSON.stringify(getFilters()), JSON.stringify(getFilter('techs')));
					setFilter('techs', [globalState.get(['auth', 'tech_code'])?.toUpperCase?.()])
				}
			} else {
				globalState.set(['ephemeral', 'can_triage_all'], false);
				setFilter('techs', [globalState.get(['auth', 'tech_code'])?.toUpperCase?.()])
			}
		}

		const globallyDisabled = parsed.DisableFS === 'Y';
		const techDisabled = !(parsed.SpclDutiesAndRights.charAt(11) === '0' || !parsed.SpclDutiesAndRights.charAt(11));

		if (globallyDisabled) {
			globalState.set(['ephemeral', 'permission_denied'], true);
			return { success: false, raw: 'Your business has disabled futuresched' };
		} else if (techDisabled) {
			globalState.set(['ephemeral', 'permission_denied'], true);
			return { success: false, raw: 'Your credentials are correct but have been denied access' };
		} else {
			globalState.set(['ephemeral', 'permission_denied'], false);
		}
	}

	globalState.set(['ephemeral', 'auth_success'], response.success);
	return response;
}

export function getMphCredentials() {
	return authenticatedApiFetch('/v3/triage/MphCreds');
}

export function getDispatches(techListString, usePast, triage, everyone) {
	return authenticatedApiFetch('/v2/minimumFutureData', { query: { techs: techListString, include_past: usePast, triage: triage, everyone } });
}

export function mphSymptoms(mphUser, mphPassword, parts) {
	return authenticatedApiFetch('/v3/searchMyPartsHelpComplaints', { query: { mph_username: mphUser, mph_password: mphPassword, part_array: parts } });
}

export function getReceivers() {
	return authenticatedApiFetch('getreceivers');
}

export function getSingleDispatch(invoiceNumber, scheduleItemId) {
	return authenticatedApiFetch('/v3/triage/getTriageJob', { query: { schedule_item_id: scheduleItemId, invoice_number: invoiceNumber } });
}

export function mphDiagnostic({ mphUser, mphPassword, mphToken, model, symptom, similar }) {
	return authenticatedApiFetch('/v3/searchMyPartsHelp', { query: { mph_username: mphUser, mph_password: mphPassword, model_number: model, customer_complaint: symptom, show_similar: similar } });
}

export function sendMail(recipient, message, whenSent) {
	return authenticatedApiFetch('/uploadsdmail', { query: { MailTo: recipient, Body: message, WhenSent: whenSent } });
}

export function setTriage(scheduleItemId) {
	return authenticatedApiFetch('/v3/updateFutureTriage', { query: { schedule_item_id: scheduleItemId, triaged: 2 } });
}

export function uploadTriageData(invoiceNumber, notes, parts) {
	return authenticatedApiFetch('/v3/uploadTriageData', { query: { invoice_number: invoiceNumber, notes: notes, parts: parts } });
}

export function submitTriage({ invoice_number, schedule_item_id, parts, notes }) {
	return authenticatedApiFetch('/v3/triage/submitTriage', { query: { invoice_number, schedule_item_id, parts, notes, triaged: 2 } })
}

function filterEmptyKeys(obj) {
	const results = {};
	for (let key in obj) {
		if (obj[key] !== undefined && obj[key] !== null) results[key] = obj[key];
	}
	return results;
}

export async function getFilteredJobs({ techs, start_date, end_date, include_office_triaged, include_untriaged, include_tech_triaged, include_unscheduled, include_return_visits }) {
	globalState.set(['ephemeral', 'jobsLoading'], true);
	const response = await authenticatedApiFetch('/v3/triage/getTriageJobs', { query: filterEmptyKeys({ techs, start_date, end_date, include_office_triaged, include_untriaged, include_tech_triaged, include_unscheduled, include_return_visits })});
	globalState.set(['ephemeral', 'jobsLoading'], false);
	return response;
}

export async function loadJobsToState({ techListString, showPast = false, triage = false, everyone = false }) {
	const response = await getDispatches(techListString, showPast, triage, everyone);

	// fix schedule item id to always be strings
	const dispatches = response.parsed?.data?.map(item => {
		item.schedule_item_id = `${item.schedule_item_id || ''}`;
		return item;
	});

	globalState.set(['ephemeral', 'dispatches'], dispatches);
}

export function loadNonTriageJobsToState({ showPast = false }) {
	return loadJobsToState({ techListString: undefined, triage: false, showPast });
}

export async function loadTriageJobsToState({ justMine }) {
	if (justMine) {
		return loadJobsToState({ everyone: false, triage: true, showPast: false });
	} else {
		const receiverResponse = await getReceivers();
		const techs = receiverResponse?.parsed?.data?.filter(item => item.Type === 'Tech').map(item => item.Code);
		techs.push(globalState.get(['auth', 'tech_code']));
		techs.push('XX');
		return loadJobsToState({ everyone: true, triage: true, showPast: false });
	}
}
